<template>
    <v-row>
        <v-col cols="12">
            <DashCard title="Buscar por:" title-icon="search">
                <v-row class="ma-0">
                    <v-col cols="12" md="3" v-if="$parent.$parent.isin('supers')">
                        <v-label>Participante</v-label>
                        <v-autocomplete 
                            solo flat outlined dense hide-details clearable 
                            v-model="nav_params.participante" 
                            :items="participantes" 
                            item-key="id" 
                            item-text="full_nombre" 
                            item-value="id" 
                            prepend-inner-icon="account_circle" 
                            placeholder="Seleccionar participante..."
                            @change="update_filtros" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-label>Curso</v-label>
                        <v-autocomplete 
                            solo flat outlined dense hide-details clearable 
                            v-model="nav_params.curso" 
                            :items="cursos" 
                            item-key="id" 
                            item-text="nombre" 
                            item-value="id" 
                            prepend-inner-icon="folder" 
                            placeholder="Seleccionar curso..."
                            @change="update_filtros" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-checkbox class="mt-0" v-model="use_rango" hide-details @change="update_filtros" label="Filtrar fecha de realización por periodo" />
                        <v-row v-if="!!nav_params.inicio && !!nav_params.termino">
                            <v-col cols="12" md="6">
                                <v-label>Desde</v-label>
                                <DatePickerText v-model="nav_params.inicio" dense flat hide-details @input="update_filtros" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-label>Hasta</v-label>
                                <DatePickerText v-model="nav_params.termino" dense flat hide-details @input="update_filtros" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </DashCard>
        </v-col>
        <v-col cols="12">
            <DashCard title="Certificados Plataforma" title-icon="list" body-class="pa-0">
                <v-data-table dense :headers="headers" :items="items" item-key="id" :page="nav_params.page" :items-per-page="20" hide-default-footer>
                    <template v-slot:item.fecha_creacion="{ item }">
                        {{ item.fecha_creacion | moment("L HH:mm") }}
                    </template>
                    <template v-slot:item.validez="{ item }">
                        <template v-if="date_valid(item.validez)">{{ item.validez | moment("L") }}</template>
                        <template v-else>{{ item.validez }}</template>
                    </template>
                    <template v-slot:item.participante="{ item }">
                        <v-chip dark color="indigo"><v-icon left>account_circle</v-icon>{{ item.participante }}</v-chip>
                    </template>
                    <template v-slot:item.tipo="{ item }">
                        <v-chip dark>{{ item.tipo }}</v-chip>
                    </template>
                    <template v-slot:item.is_entregado="{ item }">
                        <template v-if="!!item.is_entregado">
                        <v-icon left color="green">check_circle</v-icon>Entregado
                        </template>
                        <template v-else>
                            <v-icon left>error_outline</v-icon>No entregado
                        </template>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn depressed color="primary" class="subtitle-2" @click="get(item)"><v-icon left>get_app</v-icon>Emitir</v-btn>
                    </template>
                    <template v-slot:no-results>
                        <EmptyCard class="my-4" icon="inbox" text="No se han encontrado resultados" />
                    </template>
                    <template v-slot:no-data>
                        <EmptyCard class="my-4" icon="inbox" text="Sin resultados" />
                    </template>
                    <template v-slot:footer>
                        <v-divider />
                        <v-pagination v-model="nav_params.page" :length="pagination_length" @input="go_input" />
                    </template>
                </v-data-table>
            </DashCard>
        </v-col>
        <DownloadDialog ref="down_dialog" request-type="get" :raw-response="true" @on-download="update_list" />
    </v-row>
</template>

<script>
import DashCard from '@/shared/components/DashCard'
import EmptyCard from '@/shared/components/EmptyCard'
import DatePickerText from '@/shared/components/DatePickerText'
import DownloadDialog from '@/shared/components/DownloadDialog'
import { axiosHelper } from '@/helpers'
import moment from 'moment'

export default {
    components: {
        EmptyCard,
        DashCard,
        DatePickerText,
        DownloadDialog
    },
    data: () => ({
        headers: [
            { value: "participante", text: "Participante" },
            { value: "curso_nombre", text: "Curso" },
            { value: "fecha_creacion", text: "Realizado en" },
            { value: "validez", text: "Validez" },
            { value: "tipo", text: "Tipo" },
            { value: "is_entregado", text: "Entregado" },
            { value: "actions", text: "", sortable: false, width: '120px' }
        ],
        nav_params: {
            page: 1,
            participante: null,
            curso: null,
            inicio: null,
            termino: null
        },
        use_rango: false,
        index: []
    }),
    computed: {
        items() {
            if(!this.index) return [];
            return this.index.results || [];
        },
        participantes() {
            if(!this.index) return [];
            return this.index.participantes || [];
        },
        cursos() {
            if(!this.index) return [];
            return this.index.cursos || [];
        },
        pagination_length() {
            if(!this.index) return 1;
            return this.index.pages;
        }
    },
    methods: {
        update_filtros(e) {
            if(!this.nav_params.inicio && !!this.use_rango) {
                this.nav_params.inicio = moment().format("YYYY-MM-DD").toString();
            } else if(!!this.nav_params.inicio && !this.use_rango) {
                this.nav_params.inicio = null;
            }

            if(!this.nav_params.termino && !!this.use_rango) {
                this.nav_params.termino = moment().format("YYYY-MM-DD").toString();
            } else if(!!this.nav_params.termino && !this.use_rango) {
                this.nav_params.termino = null;
            }
            
            this.go_input();
        },
        go_input(num) {
            if(!!num) {
                this.nav_params.page = num;
            }
            axiosHelper.get('edu/certificados/', null, this.nav_params)
                .then(response => {
                    this.index = response.data;
                })
                .catch(error => {
                    if(error.response.status == 404) {
                        this.go_input(1);
                    }
                });
        },
        update_list() {
            if(this.$parent.$parent.isin('supers')) return;
            this.$parent.$parent.reload_dashboard();
        },
        date_valid(validez) {
            var fecha = moment(validez, 'YYYY-MM-DD');
            return fecha.isValid();
        },
        get(item) {
            this.$refs.down_dialog.show(`edu/getcertif/${item.id}/`, null, `${item.codename}.pdf`);
        }
    },
    mounted() {
        this.$parent.$parent.set_title("Certificados", "new_releases");
        this.go_input(1);
    }
}
</script>